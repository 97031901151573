jQuery(document).ready(function(){

	"use strict";

	jQuery('.jarallax').each(function(){
		let element	= jQuery(this);

		element.jarallax({
			speed: 0.5
		});
	});

	new WOW().init();

	// porta_tm_portfolios();
	// porta_tm_isotope();

	// jQuery(window).on('resize',function(){
	// 	porta_tm_portfolios();
	// 	porta_tm_isotope();
	// });

});

function porta_tm_portfolios(){

	"use strict";

	var WW					= jQuery(window).width();
	var portfolioWidth		= jQuery('.porta_tm_portfolio_home_wrap').width();
	var item				= jQuery('.porta_tm_portfolio_home_wrap .item');
	var itemTall			= jQuery('.porta_tm_portfolio_home_wrap .item.tall');
	var itemWide			= jQuery('.porta_tm_portfolio_home_wrap .item.wide');
	var mTall				= jQuery('.porta_tm_portfolio_home_wrap .item.m_tall');
	var mSimple				= jQuery('.porta_tm_portfolio_home_wrap .item.m_simple');
	var mWide				= jQuery('.porta_tm_portfolio_home_wrap .item.m_wide');
	var col3				= Math.floor(portfolioWidth/3);
	var col2				= Math.floor(portfolioWidth/2);
	var col1				= Math.floor(portfolioWidth);

	if(WW>768){
		// laptop
		item.css({
			width:col3-30+'px',
			height:Math.floor(col3*0.65)+'px',
			margin:15+'px'
		});
		itemTall.css({height:Math.floor(col3*1.3)+30+'px'});
		itemWide.css({
			width:Math.floor(col3*2)-30+'px',
			height:Math.floor(col3*1.3)+30+'px',
		});
	}else if(WW<=768 && WW > 480){
		// ipad
		item.css({
			width:col2-30+'px',
			height:Math.floor(col2*0.65)+'px',
			margin:15+'px'
		});
		itemTall.css({height:Math.floor(col2*1.3)+30+'px'});
		itemWide.css({
			width:Math.floor(col2*2)-30+'px',
			height:Math.floor(col2*1.3)+30+'px',
		});
		mTall.css({
			width:col2-30+'px ',
			height:Math.floor(col2*1.3)+30+'px',
			margin:15+'px'
		});
		mSimple.css({
			width:col2-30+'px',
			height:Math.floor(col2*0.65)+'px',
			margin:15+'px'
		});
		mWide.css({
			width:col1-30+'px',
			height:Math.floor(col2*1.3)+'px',
			margin:15+'px'
		});
	}else{
		// mobile
		item.css({
			width:col1-20+'px',
			height:Math.floor(col1*0.65)+'px',
			margin:10+'px'
		});
	}
}

function porta_tm_isotope(){

	"use strict";

	jQuery('.masonry').isotope({
		itemSelector: '.masonry_item',
		masonry: {}
	});
}
